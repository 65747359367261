$ecoDarkGrey: #4c4c4d;
$ecoLightGrey: #707071;
$ecoGreen: #008e4f;
$ecoLightText: #ffffff;
$ecoDarkText: #000000;

$redAlert: #a30202;

$breakpoint-phone:480px;


:export {
    ecoDarkGrey: $ecoDarkGrey;
    ecoLightGrey: $ecoLightGrey;
    ecoGreen: $ecoGreen;
    ecoLightText:$ecoLightText;
    ecoDarkText:$ecoDarkText;
    redAlert:$redAlert;

}