@import "./ecoThemeVariables.module.scss";

.CustomerTruckOrdersApp {

    .header {
        background-color: $ecoDarkGrey;
        // box-shadow: 3px 7px 19px 1px #4c4c4d;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        padding:12px;
    }
    @media (min-width: $breakpoint-phone) {
        .contentWrapper {
            padding: 20px;
        }
    }

    .loginButtonWidth{
        width:85px
    }

    .ecoButton{
        background-color: $ecoGreen;
        border-color: $ecoGreen;
    }

    .companySection{
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        min-height:120px;
        padding:12px;

        .header1{
            font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
            color:$ecoDarkGrey;
            font-weight: 600;
            font-size: 22px;
        }
    
    }
    
}
