@import "./ecoThemeVariables.module.scss";

.OrderHistory {
    // min-height: 800px;
    // border: 1px solid black;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding:12px;

    // .lineItemArea{
    //     min-width: 600px;
    // }

    .header1{
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        color:$ecoDarkGrey;
        font-weight: 600;
        font-size: 22px;
    }

    .header2{
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        color:$ecoLightGrey;
        font-weight: 500;
        font-size: 16px;
    }

    .ecoButton{
        background-color: $ecoGreen;
        border-color: $ecoGreen;
        //color:#ffffff;
    }

    .ecoDefaultButton{
        background-color: $ecoLightGrey;
        border-color: $ecoLightGrey;
        color:#ffffff;
    }
}
