@import "./ecoThemeVariables.module.scss";

.AddressCard{
    
    color: #ffffff;
    padding: 5px 10px;
    width: 300px;
    min-height:88px;
    border-radius: 10px;
    // box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    font-size: 1em;
    
}

.withHover:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
}

.card1 {
  background: radial-gradient($ecoLightGrey, $ecoDarkGrey);
}

.blankCard{
  background: radial-gradient(#e7e7e7, #b4b4b4);
  color:$ecoDarkGrey;
}
  
.title {
  font-weight: 400;
  font-size: 1.1em;
  color: #ffffff;
  padding:0;
  margin:0;
}