@import "./ecoThemeVariables.module.scss";

.OrderHistoryCard {
    // border: 1px solid $ecoDarkGrey;
    // max-width: 300px;
    // border-radius: 8px;
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 3px 3px 1px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    // box-shadow:5px 5px 10px $ecoDarkGrey;
    padding: 15px 25px;
    // min-width: 975px;
    // border: 15px solid radial-gradient($ecoLightGrey, $ecoDarkGrey);
}

.hrArrowLine {
    width: 100%;
    height: 1px;
    margin-left: 0;
    margin-right: 0;
    background-color: $ecoDarkGrey;
    border: 0 none;
}

.missingValue {
    color: $redAlert;
    :global(.ms-label){
        color: $redAlert;
    }
}

.ecoButton{
    background-color: $ecoGreen;
    border-color: $ecoGreen;
    //color:#ffffff;
}